import React from 'react'
import Autosuggest from 'react-autosuggest'
import axios from 'axios'
import { debounce } from 'throttle-debounce'

import './styles.css'

class AutoComplete extends React.Component {
  state = {
    value: '',
    suggestions: []
  }

  componentWillMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    )
  }

  renderSuggestion = suggestion => {
    console.log(suggestion)
    return (
      <div className="result">
        <div>{suggestion.full_address}</div>
        <div className="homeID">{suggestion.home_id}</div>
      </div>
    )
  }

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    var username = 'gordon';
    var passwd = '@eD9KtJk#CZCZuPnA9T73b9$CY#LqERs';
    var basicAuth = 'Basic ' + btoa(username + ':' + passwd);
    axios
      .post('https://opensearch.hommati.com:9200/hommati-residential-search-addresses/_search', 
        {
          "suggest": {
            "autocomplete": {
              "prefix": value,
              "completion": {
                "field": "full_address",
                "size": 6
              }
            }
          }
        } // query to pass
      , 
      {
        headers: 
        { 
          'Authorization': basicAuth
        }
      }
      )
        .catch(function(error){
          if (error.response){
            console.log(error);
          }
        })
      .then(res => {
        const results = res.data.suggest.autocomplete[0].options.map(h => h._source)
        this.setState({ suggestions: results })
        console.log(res.data.suggest.autocomplete[0].options)
      })
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })
  }

  render() {
    console.log('running...');
    const { value, suggestions } = this.state

    const inputProps = {
      placeholder: 'Street Address or ID',
      value,
      onChange: this.onChange
    }

    return (
      <div className="App">
        <h1>AutoComplete Demo</h1>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={suggestion => suggestion.full_address}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    )
  }
}

export default AutoComplete;